<template>
  <div>
    <div class="banner">
      <div class="item" v-if="!!dataList && dataList.length > 0">
        <img :src="dataList[currentIndex]" />
      </div>
      <div class="page" v-if="!!dataList && dataList.length > 1">
        <ul>
          <!-- <li @click="gotoPage(prevIndex)">&lt;</li> -->
          <li
            v-for="(item, index) in dataList"
            @click="gotoPage(index)"
            :class="{ current: currentIndex === index }"
            :key="index"
          >
            <!-- {{ index + 1 }} -->
          </li>
          <!-- <li @click="gotoPage(nextIndex)">&gt;</li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      default: [],
    },
  },
  data() {
    return {
      currentIndex: 0,
      timer: null,
    };
  },
  computed: {
    //下一张
    nextIndex() {
      if (this.currentIndex === this.dataList.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
  },

  mounted() {
    this.runInv();
  },
  methods: {
    gotoPage(index) {
      this.currentIndex = index;
    },
    runInv() {
      this.timer = setInterval(() => {
        if (
          this.dataList === null ||
          this.dataList === undefined ||
          this.dataList.length === 0
        ) {
          this.gotoPage(0);
          return;
        }
        if (this.nextIndex > this.dataList.length) {
          this.gotoPage(0);
        } else {
          this.gotoPage(this.nextIndex);
        }
      }, 3000);
    },
  },
  beforeDestroy() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="less" scoped>
ul li {
  list-style: none;
  float: left;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);

  &:nth-child(n + 2) {
    margin-left: 10px;
  }
}
.banner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  //   margin-top: 60px;
  width: 2.24rem;
}
.banner img {
  width: 2.24rem;
  height: 20.2vh;
  display: block;
}
.banner .page {
  position: absolute;
  right: 0;
  bottom: -1.6vh;
  width: 100%;
  display: flex;
  justify-content: center;
}
.banner .page ul {
}
.current {
  background-color: #2effea;
}
</style>
