var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"surroundContainer"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.remarkData.companyName))])]),_c('div',{staticClass:"chart-box"},[_c('div',{staticClass:"content-left",class:[
          {
            no: _vm.remarkData.imgs.length == 0,
          },
        ],domProps:{"innerHTML":_vm._s(_vm.remarkData.text)}}),_c('div',{staticClass:"banner-right",class:[
          {
            no: _vm.remarkData.imgs.length == 0,
          },
        ]},[_c('shufflingImg',{attrs:{"dataList":_vm.remarkData.imgs}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }