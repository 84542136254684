<template>
  <div>
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">{{ remarkData.companyName }}</div>
      </div>
      <div class="chart-box">
        <div
            :class="[
            {
              no: remarkData.imgs.length == 0,
            },
          ]"
            class="content-left"
            v-html="remarkData.text"
        ></div>
        <div
            :class="[
            {
              no: remarkData.imgs.length == 0,
            },
          ]"
            class="banner-right"
        >
          <shufflingImg :dataList="remarkData.imgs"></shufflingImg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import shufflingImg from "./shufflingImg";

export default {
  components: {
    shufflingImg,
  },
  props: {
    remarkData: {
      default: {
        text: "",
        imgs: [],
      },
    },
  },
};
</script>
<style lang="less" scoped>
.surroundContainer {
  background-image: url("~@/assets/imgs/itemBoxBJ-cb.png");
  width: 6.7rem;
  height: 29vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header {
    display: flex;
    height: 3.9vh;
    align-items: end;
    line-height: 1;

    .icon {
      background-image: url("~@/assets/imgs/itemBox_header_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 0.3rem;
      height: 1.85vh;
      margin-left: 0.35rem;
    }

    .title {
      // font-weight: bold;
      color: #ffffff;
      font-size: 0.16rem;
      margin-left: 0.16rem;
    }
  }
}

.chart-box {
  display: flex;
  margin-top: 1.2vh;
}

.banner-right {
  width: 2.24rem + 0.16rem;

  &.no {
    display: none;
  }
}

.content-left {
  padding-right: 0.05rem;
  width: calc(100% - 2.24rem - 0.4rem);
  margin-left: 0.16rem;
  color: #c9e0ff !important;
  font-size: 0.12rem;
  font-weight: 400;
  line-height: 2.2;
  overflow-y: scroll;
  max-height: 23vh;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &.no {
    width: calc(100% - 0.32rem)
  }
}
</style>
